export default {
  allOurProductsNew: {
    id: 'header_all_our_products',
    defaultMessage: 'All our products',
  },
  aboutUs: {
    id: 'header_to_about_us',
    defaultMessage: 'About us',
  },
  sellYourTech: {
    id: 'header_to_buyback',
    defaultMessage: 'Sell your tech',
  },
  help: {
    id: 'header_to_help',
    defaultMessage: 'Help',
  },
}
