<template>
  <section>
    <MenuTitle
      class="fixed inset-x-0 top-0 z-10 h-52"
      :text-color="current.textColor"
      :title="current.title"
      @previous="back"
    />
    <Transition
      appear
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="opacity-0 border-opacity-0"
    >
      <div class="bg-static-default-low fixed inset-x-0 top-52 h-[54px]"></div>
    </Transition>
    <Transition
      appear
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="h-0 translate-y-[54px]"
      enter-to-class="h-full translate-y-0"
    >
      <div
        class="bg-static-default-low border-static-default-low fixed inset-x-0 bottom-0 top-52 border-t"
      >
        <nav class="flex h-full flex-col justify-start overflow-scroll">
          <FirstAnimationMenuItems
            v-if="firstState"
            :links="links"
            :menu-items="current.items"
            @link-click="onLinkClick"
            @menu-item-click="onMenuItemClick"
          />
          <MenuItems
            v-else-if="breadcrumb.length === 1"
            :current="current.currentItem"
            :links="links"
            :menu-items="current.items"
            :with-extra-menu="true"
            @link-click="onLinkClick"
            @menu-item-click="onMenuItemClick"
          />
          <MenuItems
            v-else
            :key="current.title"
            :current="current.currentItem"
            :menu-items="current.items"
            :with-extra-menu="false"
            @menu-item-click="onMenuItemClick"
          />
        </nav>
      </div>
    </Transition>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import type { navigationAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import { ABOUT_US } from '~/scopes/about-us/routes.constants'
import { HELP_CENTER } from '~/scopes/help-center/routes'

import { useNavigation } from '../composables/useNavigation'

import translations from './BurgerMenu.translations'
import FirstAnimationMenuItems from './FirstAnimationMenuItems.vue'
import MenuItems from './MenuItems.vue'
import MenuTitle from './MenuTitle.vue'

const i18n = useI18n()

const { menu } = useNavigation()
const breadcrumb = ref<
  {
    title?: string
    textColor?: string
    items: navigationAPI.MenuItem[]
    currentItem?: navigationAPI.MenuItem
  }[]
>([{ items: menu.value ?? [] }])
const current = computed(() => {
  return breadcrumb.value[breadcrumb.value.length - 1]
})
const firstState = ref(true)

const { currentLocale: locale } = useI18n()

const links = [
  {
    name: 'footer_about_us',
    href: { name: ABOUT_US, params: { locale } },
    label: i18n(translations.linkAboutUs),
  },
  {
    name: 'footer_help',
    href: { name: HELP_CENTER.HOME, params: { locale } },
    label: i18n(translations.linkHelp),
  },
]

const { toggle: toggleBurgerMenu } = useNavigation()

function back() {
  breadcrumb.value.pop()
}
function forward(item: navigationAPI.MenuItem) {
  firstState.value = false
  breadcrumb.value.push({
    items: item.children.map(({ image, ...subitem }) =>
      // Image only for department (aka level 1)
      breadcrumb.value.length === 1 ? { ...subitem, image } : subitem,
    ),
    title: item.title,
    textColor: item.style?.text_color,
    currentItem: breadcrumb.value.length === 1 ? item : undefined,
  })
}

const { trackClick } = useTracking()
function onMenuItemClick({ item }: { item: navigationAPI.MenuItem }) {
  if (item.children?.length > 0) {
    forward(item)
  } else {
    trackClick({
      zone: 'nav',
      name: breadcrumb.value
        .map(({ title }) => title)
        .concat(item.title)
        .join('_'),
    })
    toggleBurgerMenu()
  }
}

function onLinkClick(link: { name: string }) {
  trackClick({ zone: 'nav', name: link.name })
  toggleBurgerMenu()
}
</script>
