<template>
  <div>
    <div class="flex gap-24">
      <RevLink
        v-for="link in mainLinks"
        :key="link.name"
        class="flex items-center gap-4"
        data-test="footer-link"
        :to="link.url"
        :underlined="false"
        @click="trackClick({ name: link.trackingName, zone: 'header' })"
      >
        <component
          :is="link.icon"
          v-if="link.icon"
          class="text-static-brand-mid"
        />
        <span class="body-2-bold" :class="link.class">
          {{ i18n(link.title) }}
        </span>
      </RevLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { RevLink } from '@ds/components/Link'
import { IconSwap } from '@ds/icons/IconSwap'

import { ABOUT_US } from '~/scopes/about-us/routes.constants'
import { CMS } from '~/scopes/cms/routes-names'
import { HELP_CENTER } from '~/scopes/help-center/routes'

import translations from './Header.translations'

const i18n = useI18n()

const locale = useI18nLocale()
const { trackClick } = useTracking()

const { features }: { features: { buyback: { enabled: boolean } } } =
  useMarketplace()

interface MenuLink {
  name: string
  title: { id: string; defaultMessage: string }
  trackingName: string
  url: string
  class?: string
  icon?: typeof IconSwap
}

const aboutUsLink: MenuLink = {
  name: ABOUT_US,
  title: translations.aboutUs,
  trackingName: 'trustedLink',
  url: `/${locale}/about-us`,
}
const buybackLink: MenuLink = {
  name: CMS.BUYBACK,
  title: translations.sellYourTech,
  trackingName: 'buyback',
  class: tw`text-static-brand-mid`,
  icon: IconSwap,
  url: `/${locale}/buyback/home`,
}

const helpCenterLink: MenuLink = {
  name: HELP_CENTER.HOME,
  title: translations.help,
  trackingName: 'helpContact',
  url: `/${locale}/help`,
}
const mainLinks = [
  ...(features.buyback.enabled ? [buybackLink] : []),
  aboutUsLink,
  helpCenterLink,
]
</script>
