import { useNuxtData, useState } from '#imports'
import { computed } from 'vue'

import { navigationAPI } from '@backmarket/http-api'
import type { MenuItem } from '@backmarket/http-api/src/api-specs-navigation-experience/navigation'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

function cleanMenu(items: navigationAPI.MenuItem[]): navigationAPI.MenuItem[] {
  return items
    .filter((item) => item.title && item.link)
    .map(
      ({ children, ...item }) =>
        <navigationAPI.MenuItem>{
          ...item,
          children,
        },
    )
}

export function useNavigation() {
  const state = useState<'closed' | 'open'>('burger-menu-state', () => 'closed')
  const items = useState<MenuItem[] | null>('items', () => null)

  async function fetchItems() {
    const { data } = await useHttpFetch(navigationAPI.getSidebar, {
      default: () => [],
      transform: cleanMenu,
      getCachedData: (key) =>
        useNuxtData<navigationAPI.MenuItem[]>(key).data.value,
    })
    items.value = data.value
  }

  if (!items.value) {
    void fetchItems()
  }

  function toggle() {
    state.value = state.value === 'open' ? 'closed' : 'open'
  }

  const isOpen = computed(() => state.value === 'open')

  return {
    menu: items,
    state,
    toggle,
    isOpen,
  }
}
