<template>
  <div
    class="bg-static-default-low text-static-default-hi flex items-center justify-center"
  >
    <RevButtonRounded
      v-if="title"
      class="absolute left-12 top-1/2 -translate-y-1/2 cursor-pointer"
      :icon="IconArrowLeft"
      variant="secondary"
      @click="$emit('previous')"
    />

    <h1
      v-if="title"
      class="text-static-default-hi body-1-bold heading-3 -my-2 line-clamp-1 overflow-hidden text-ellipsis"
      :style="{ color: textColor }"
    >
      {{ title }}
    </h1>

    <TheLogo v-else class="text-static-default-hi my-4 flex" />

    <RevButtonRounded
      class="absolute right-12 top-1/2 -translate-y-1/2 cursor-pointer"
      :icon="IconCross"
      variant="secondary"
      @click="close"
    />
  </div>
</template>

<script lang="ts" setup>
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconCross } from '@ds/icons/IconCross'

import TheLogo from '~/scopes/navigation/components/TheLogo.vue'
import { useNavigation } from '~/scopes/navigation/composables/useNavigation'

withDefaults(
  defineProps<{
    title?: string
    textColor?: string
  }>(),
  {
    title: undefined,
    textColor: 'inherit',
  },
)

defineEmits<{
  previous: []
}>()

function close() {
  useNavigation().toggle()
}
</script>
