<template>
  <Combobox
    v-if="!error"
    input-id="searchBar-input"
    :popular-searches="popularSearches || []"
    :suggestions="suggestions"
    @change="changeHandler"
    @submit="submitHandler"
  />
</template>

<script setup lang="ts">
import { createError, navigateTo, useRouter } from '#imports'
import { ref } from 'vue'

import { searchAPI } from '@backmarket/http-api'
import { useHttpLazyFetch } from '@backmarket/nuxt-module-http/useHttpLazyFetch'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import { useSearchIndexByQuery } from '../composables/useSearch'
import { useAlgoliaApiKey, useAlgoliaConfiguration } from '../helpers/helpers'

import Combobox, { type Item } from './components/Combobox.vue'

const { trackFormSubmit, trackSearchAnalytics, trackSearchTerm } = useTracking()
const router = useRouter()
const visitorId = useVisitorId()
const error = ref()

const { data: popularSearches } = useHttpLazyFetch(searchAPI.getPopular, {
  default: () => [],
})

const searchConfig = await useAlgoliaConfiguration('search-bar')

if (searchConfig === null) {
  error.value = createError({
    statusCode: 500,
    statusMessage: 'Unable to retrieve search configuration',
  })
  throw error.value
}
const apiKeyConfig = await useAlgoliaApiKey(searchConfig.indexType)

if (apiKeyConfig === null) {
  error.value = createError({
    statusCode: 500,
    statusMessage: 'Unable to get algolia API key',
  })
  throw error.value
}

const {
  query: searchQuery,
  queryID,
  results: suggestions,
} = useSearchIndexByQuery('', {
  index: searchConfig.indexes.active,
  apiKey: apiKeyConfig.apiKey,
})

function changeHandler(query: string) {
  searchQuery.value = query
}

function goToSearchLP({ query }: { query: string }) {
  const link = {
    type: 'internal',
    name: PRODUCT_LIST_ROUTES.SEARCH_RESULTS,
    query: { q: query },
  }
  router.push(toNuxtLink(link))
}

function goToProductLP({ index, item }: { index: string; item: Item }) {
  if (item.objectID) {
    trackSearchAnalytics({
      type: 'click',
      objectId: item.objectID,
      searchQueryID: queryID.value,
      tokenId: visitorId,
      position: index + 1,
      index: searchConfig?.indexes.active.name ?? 'unknown',
    })
  }
  navigateTo(toNuxtLink(item.link), { external: item.link.type === 'external' })
}

function submitHandler({
  query,
  index,
  item,
}: {
  query: string
  index: string
  item: Item
}) {
  trackFormSubmit({
    zone: 'header_searchbar',
    name: item?.title ?? query,
    payload: {
      index_used: index,
      search_type: item?.source ?? 'userInput',
    },
  })

  trackSearchTerm(query)

  if (item) {
    goToProductLP({ index, item })
  } else if (query) {
    goToSearchLP({ query })
  }
}
</script>
