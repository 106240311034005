<template>
  <div
    class="flex flex-wrap items-center gap-16 px-20 py-16 lg:gap-56 lg:px-32 lg:py-20"
  >
    <BurgerMenuIcon class="block flex-1 grow lg:hidden" />
    <TheLogo />
    <NavLinks class="hidden lg:block" />
    <SearchBar class="order-last w-full grow lg:order-none lg:flex-1" />
    <UserIcons class="flex-1 grow justify-end lg:grow-0" />
    <MenuFullscreen
      v-show="burgerMenuState === 'open'"
      class="z-20 lg:hidden"
    />
  </div>
</template>

<script setup lang="ts">
import SearchBar from '~/scopes/search/SearchBar/SearchBar.vue'

import { useNavigation } from '../composables/useNavigation'

import BurgerMenuIcon from './BurgerMenuIcon.vue'
import MenuFullscreen from './MenuFullscreen.vue'
import NavLinks from './NavLinks.vue'
import TheLogo from './TheLogo.vue'
import UserIcons from './UserIcons.vue'

const { state: burgerMenuState } = useNavigation()
</script>
