<template>
  <header
    id="header"
    class="bg-surface-default-low sticky top-0 z-10 transition-all duration-200"
    :class="{ '-translate-y-full': headerIsHidden }"
  >
    <HeaderBar />
    <TopSalesBarMenu />
  </header>
</template>

<script setup lang="ts">
import { useHideOnScroll } from '../composables/useHideOnScroll'

import HeaderBar from './HeaderBar.vue'
import TopSalesBarMenu from './TopSalesBarMenu.vue'

const { hidden: headerIsHidden } = useHideOnScroll()
</script>
