<template>
  <div>
    <div id="skip-to-content-wrapper" class="fixed z-50"></div>
    <div id="toast-wrapper" class="fixed z-50">
      <TheToast to="#toast-wrapper" />
    </div>

    <CookiesModal />

    <BrazePrompt />

    <slot name="header">
      <TheHeader />
    </slot>
    <slot />

    <slot name="footer">
      <Footer />
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { useHead } from '#imports'

import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import TheToast from '@backmarket/nuxt-module-toast/TheToast.vue'
import CookiesModal from '@backmarket/nuxt-module-tracking/CookiesModal.vue'

import Footer from '~/scopes/navigation/components/Footer/Footer.vue'
import TheHeader from '~/scopes/navigation/components/TheHeader.vue'

import { useAppsflyer } from '../scopes/appsflyer/composables/useAppsflyer'
import BrazePrompt from '../scopes/braze/BrazePrompt.vue'

useHead({
  htmlAttrs: {
    lang: useI18nLocale(),
  },
})
useAppsflyer()
</script>
