<template>
  <aside>
    <div class="h-full overflow-hidden hover:overflow-auto">
      <div v-for="category in categories" :key="category.id">
        <div
          v-if="category.title"
          class="border-static-default-hi flex w-full flex-row border-b border-solid px-24 pb-32 pt-56"
        >
          <div v-if="category.seeAll.active" class="min-w-160">
            <RevLink
              class="inline-block w-full"
              :title="category.title"
              :to="category.link"
              :underlined="false"
              @click="trackAndClose(category.seeAll)"
            >
              <div class="font-body-1-bold">
                {{ category.title }}
              </div>
              <div class="underline">
                {{ $t(translations.storePanelAllLabelNew) }} &gt;
              </div>
            </RevLink>
          </div>

          <div class="w-full columns-3">
            <RevLink
              v-for="subcat in category.children"
              :key="subcat.id"
              class="inline-block w-full"
              :title="subcat.title"
              :to="subcat.link"
              :underlined="false"
              @click="trackAndClose(subcat)"
            >
              {{ subcat.title }}
            </RevLink>
          </div>
        </div>

        <RevButtonBase
          v-if="category.image && category.link"
          :to="category.link"
          @click="closeNavigation"
        >
          <RevIllustration
            :alt="category.title"
            class="block h-200 w-[800px]"
            :height="197"
            :src="category.image"
            :title="category.title"
            :width="800"
          />
        </RevButtonBase>
      </div>
    </div>

    <RevButtonRounded
      :aria-label="$t(designSystemTranslations.close)"
      class="absolute left-full top-2 ml-2"
      :icon="IconCross"
      variant="secondary"
      @click="closeNavigation"
    />
  </aside>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { navigationAPI } from '@backmarket/http-api'
import designSystemTranslations from '@backmarket/nuxt-layer-design-system/translations/DesignSystem.translations'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { IconCross } from '@ds/icons/IconCross'

import { useNavigation } from '../composables/useNavigation'

import translations from './MenuPanel.translations'

const $t = useI18n()

const props = defineProps<{ item: navigationAPI.MenuItem }>()

const categories = computed(() => {
  if (isEmpty(props.item.children)) {
    return []
  }

  return props.item.children.map(
    ({ children = [], ...category }, categoryIndex) => ({
      ...category,
      seeAll: {
        active: Boolean(category.link),
        zone: 'nav',
        name: `${props.item.id}-${props.item.title}_${categoryIndex + 1}-${
          category.title
        }_see-all`,
      },
      children: children
        .filter((child) => child.link)
        .map((child, childIndex) => ({
          ...child,
          zone: 'nav',
          name: `${props.item.id}-${props.item.title}_${categoryIndex + 1}-${
            category.title
          }_${childIndex + 1}-${child.title}`,
        })),
    }),
  )
})

const { trackClick } = useTracking()

function trackAndClose({ zone, name }: { zone: string; name: string }) {
  trackClick({ zone, name })
  useNavigation().toggle()
}

function closeNavigation() {
  useNavigation().toggle()
}
</script>
