import { useRuntimeConfig } from '#imports'
import { ref, watch } from 'vue'

import { createInMemoryCache } from '@algolia/cache-in-memory'
import type {
  AlgoliaAPISearchResultHitHighlighted,
  AlgoliaSearchResult,
  IndexSearch,
} from '@backmarket/http-api/src/api-specs-search-reco/search'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import algoliasearch from 'algoliasearch'

import { getHighlightedTitleFromSearch } from '../helpers/helpers'

export const useSearchIndexByQuery = (
  initialQuery: string,
  { index, apiKey }: { index: IndexSearch; apiKey: string },
) => {
  const runtimeConfig = useRuntimeConfig()
  const { ALGOLIA_ID } = runtimeConfig.public

  const query = ref(initialQuery)
  const queryID = ref('')
  const results = ref<AlgoliaAPISearchResultHitHighlighted[]>([])

  const algoliaIndex = algoliasearch(ALGOLIA_ID, apiKey, {
    responsesCache: createInMemoryCache(),
    requestsCache: createInMemoryCache({ serializable: false }),
  }).initIndex(index.name)

  const search = useDebounceFn(async function debounceSearch() {
    const searchResult = await algoliaIndex.search<AlgoliaSearchResult>(
      query.value,
      {
        ...index.queryParams,
        highlightPreTag: '<span class="body-1">',
        highlightPostTag: '</span>',
      },
    )

    queryID.value = searchResult?.queryID ?? ''

    results.value =
      searchResult?.hits.map((hit) => ({
        ...hit,
        highlightedTitle: getHighlightedTitleFromSearch(hit),
      })) ?? []
  }, 500)

  watch(
    query,
    (newQuery, oldQuery) => {
      if (newQuery && newQuery !== oldQuery) {
        void search()
      }
    },
    // if the initial value is different from an empty string, it calls the search engine to update the results
    { immediate: true },
  )

  return { query, results, queryID }
}
